import Guid from "@utils/guid";
// @ts-ignore
import wikiAssistantLoadingTemplate from "@tooltips/wikiAssistantLoading.html?raw";
// @ts-ignore
import wikiAssistantTemplate from "@tooltips/wikiAssistant.html?raw";
// @ts-ignore
import articlesAssistantLoadingTemplate from "@tooltips/articlesAssistantLoading.html?raw";
// @ts-ignore
import articlesAssistantTemplate from "@tooltips/articlesAssistant.html?raw";
// @ts-ignore
import assistantLoadingTemplate from "@tooltips/assistantLoading.html?raw";
// @ts-ignore
import assistantTemplate from "@tooltips/assistant.html?raw";
// @ts-ignore
import badgeLoadingTemplate from "@tooltips/badgeLoading.html?raw";
// @ts-ignore
import badgeTemplate from "@tooltips/badge.html?raw";
// @ts-ignore
import wikipediaLoadingTemplate from "@tooltips/wikipediaLoading.html?raw";
// @ts-ignore
import wikipediaTemplate from "@tooltips/wikipedia.html?raw";
// @ts-ignore
import baseViewTemplate from "@tooltips/baseView.html?raw";
// @ts-ignore
import draftViewTemplate from "@tooltips/draftView.html?raw";
// @ts-ignore
import sharedViewTemplate from "@tooltips/sharedView.html?raw";
// @ts-ignore
import viewSharedTemplate from "@tooltips/viewShared.html?raw";
// @ts-ignore
import navTemplate from "@tooltips/nav.html?raw";
// @ts-ignore
import wikiLoadingAssistantLoadingTemplate from "@tooltips/wikiLoadingAssistantLoading.html?raw";

/** @type {Map<HTMLElement, HTMLElement>} */
const tooltipsInMemory = new Map();

export const useTooltip = () => {
  /*
   * Monitio Tooltip hook.
   * For more details of please refer to the docs at:
   * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/917831805/Tooltip
   */

  /** @type {{[key:string]: string}} */
  const templates = {
    wikiAssistantLoading: wikiAssistantLoadingTemplate,
    wikiAssistant: wikiAssistantTemplate,
    articlesAssistantLoading: articlesAssistantLoadingTemplate,
    articlesAssistant: articlesAssistantTemplate,
    assistantLoading: assistantLoadingTemplate,
    assistant: assistantTemplate,
    badgeLoading: badgeLoadingTemplate,
    badge: badgeTemplate,
    wikipediaLoading: wikipediaLoadingTemplate,
    wikipedia: wikipediaTemplate,
    baseView: baseViewTemplate,
    draftView: draftViewTemplate,
    sharedView: sharedViewTemplate,
    viewShared: viewSharedTemplate,
    nav: navTemplate,
    wikiLoadingAssistantLoading: wikiLoadingAssistantLoadingTemplate,
  };

  const mouseCoordinates = { x: 0, y: 0 };

  const validPositions = [
    "top-left",
    "top-center",
    "top-right",
    "left",
    "right",
    "bottom-left",
    "bottom-center",
    "bottom-right",
  ];

  /** @type {{element: HTMLElement | null, isActive: boolean, type: string|null, parent: HTMLElement| null}} */
  const currentTooltip = {
    element: null,
    isActive: false,
    type: null,
    parent: null,
    clickable: null,
  };

  let tooltipTimeout = null;
  let updateTooltipTimeout = null;
  let timeout = null;

  /** @type {HTMLElement} */
  let lastElement;

  const cleanTooltips = () => {
    tooltipsInMemory.forEach((element) => {
      element.remove();
    });

    //Just in case all the tooltips werent removeddo do this
    document.querySelectorAll(".m-tooltip").forEach((el) => {
      el.remove();
    });

    currentTooltip.element = null;
    currentTooltip.isActive = false;
    currentTooltip.type = null;
    currentTooltip.parent = null;
  };

  /**
   *
   * @param {HTMLElement} element
   * @param {number} tooltipWidth
   * @param {number} tooltipHeight
   * @param {HTMLElement} tooltip
   */
  const updateTooltipPosition = (
    element,
    tooltipWidth,
    tooltipHeight,
    tooltip
  ) => {
    tooltip.style.display = "none";

    const offsetX = 10;
    const offsetY = 16;
    const margin = 8;

    let posX = mouseCoordinates.x + offsetX;
    let posY = mouseCoordinates.y + offsetY;

    //top max
    if (posY < margin) {
      posY = margin;
    }
    //left max
    if (posX < margin) {
      posX = margin;
      posY += 6;
    }
    //right max
    if (posX + tooltipWidth > window.innerWidth - margin) {
      posX = window.innerWidth - tooltipWidth - margin;
      posY += 6;
    }
    //bottom max
    if (posY + tooltipHeight > window.innerHeight - margin) {
      posY = window.innerHeight - tooltipHeight - margin;
    }

    tooltip.style.top = `${posY}px`;
    tooltip.style.left = `${posX}px`;

    if (tooltip.classList.contains("m-tooltip--invisible")) {
      tooltip.classList.remove("m-tooltip--invisible");
    }

    clearTimeout(updateTooltipTimeout);

    if (document.getElementsByClassName("m-dropdown").length > 0) {
      unmountTooltip();
    } else if (element) {
      updateTooltipTimeout = setTimeout(() => {
        if (posX !== 0 && posY !== 0) {
          tooltip.style.display = "flex";
        }
      }, 300);
    } else unmountTooltip();
  };

  /**
   * @description function responsable for reading the source element again and updating the inner contents of the tooltip
   * @param {HTMLElement} element The original HTML element that has the tooltip to update
   */
  const updateTooltipContent = (element) => {
    const tooltip = tooltipsInMemory.get(element);
    if (!tooltip) return;

    const content = element.getAttribute("data-tooltip-content");
    if (!content) return;

    const template = element.getAttribute("data-tooltip-template");
    if (template) {
      tooltip.innerHTML = computeTemplate(template, content);
      const currentSize = tooltip.getBoundingClientRect();

      if (window.innerHeight < currentSize.y + currentSize.height) {
        tooltip.style.top = "unset";
        tooltip.style.bottom = "12px";
      }

      if (window.innerWidth < currentSize.x + currentSize.width) {
        tooltip.style.left = "unset";
        tooltip.style.right = "12px";
      }
    } else tooltip.innerText = content;
  };

  /**
   *
   * @param {HTMLElement} element Original element that originated this tooltip
   * @param {HTMLElement} draftTooltip
   * @param {string} positions
   */
  const createStaticTooltip = (element, draftTooltip, positions) => {
    element.style.position = "relative";
    const tooltip = draftTooltip;

    // Create classes
    let [position, direction] = positions.split("-"); // eslint-disable-line
    let positionClasses = [];

    if (position == "top" || position == "bottom") {
      if (!direction) direction = "right";
      positionClasses = [`m-tooltip--${position}`, `m-tooltip--${direction}`];
    } else if (position == "left" || position == "right") {
      positionClasses = [`m-tooltip--${position}`];
    } else {
      positionClasses = ["m-tooltip--bottom", "m-tooltip--right"];
    }

    tooltip.classList.add(...positionClasses);

    //calculate position relative to body
    document.body.appendChild(tooltip);
    const elementHeight = tooltip.offsetHeight;
    document.body.removeChild(tooltip);

    //tooltip position relative to lines length
    let spacement = 0;
    if (tooltip.classList.contains("m-tooltip--top")) {
      spacement = (elementHeight - 24) / 16;
    }

    // Tooltips do not mount on svg child nodes
    if (element.tagName != "rect") {
      //calculate position relative to parent element
      element.appendChild(tooltip);
      tooltip.classList.add("m-tooltip--nowrap");
      const tooltipBox = tooltip.getBoundingClientRect();
      tooltip.classList.remove("m-tooltip--nowrap");
      element.removeChild(tooltip);

      tooltip.style.left = `${tooltipBox.x}px`;
      if (tooltipBox.y > (window.innerHeight * 2) / 3)
        tooltip.style.bottom = `${
          window.innerHeight - (tooltipBox.y + 65 + 16 * spacement)
        }px`;
      else tooltip.style.top = `${tooltipBox.y - 16 * spacement}px`;

      //remove classes related to relative position
      tooltip.classList.remove(...positionClasses);

      if (tooltipBox.height > window.innerHeight - tooltipBox.y - 12) {
        const movetop =
          window.innerHeight - tooltipBox.y - tooltipBox.height - 12;
        tooltip.style.transform = `translateY(${movetop}px)`;
      }

      if (tooltipBox.width > window.innerWidth - tooltipBox.x - 12) {
        const moveleft =
          window.innerWidth - tooltipBox.x - tooltipBox.width - 12;
        tooltip.style.transform = `translateX(${moveleft}px)`;
      }
    }

    currentTooltip.element = tooltip;
    currentTooltip.isActive = true;
    currentTooltip.type = "static";
    currentTooltip.parent = element;
    currentTooltip.clickable =
      element.attributes["data-tooltip-clickable"]?.value;

    tooltipsInMemory.set(element, tooltip);
    document.body.appendChild(tooltip);

    // is only able to calculate svg child node position after mounting on DOM
    if (element.tagName == "rect") {
      const elementBox = element.getBoundingClientRect();
      const tooltipBox = tooltip.getBoundingClientRect();

      tooltip.classList.remove(...positionClasses);

      if (window.innerWidth / 2 > elementBox.x) {
        // Tooltip opens from left
        tooltip.style.left = `${
          elementBox.x + elementBox.width / 2 - tooltipBox.width / 2
        }px`;
      } else {
        // Tooltip opens from right
        tooltip.style.right = `${
          window.innerWidth -
          elementBox.x -
          elementBox.width / 2 -
          tooltipBox.width / 2
        }px`;
      }

      if (
        window.innerHeight * 0.2 <
        window.innerHeight - (elementBox.y + elementBox.height)
      ) {
        // Tooltip can open bottom
        const movetop = elementBox.y + elementBox.height + 4;
        tooltip.style.top = `${movetop}px`;
      } else {
        // Tooltip needs to open top
        const movebottom = window.innerHeight - elementBox.y + 4;
        tooltip.style.bottom = `${movebottom}px`;
      }
    }

    if (!element.id) {
      console.error("Unable to mount tooltip: element is missing id", element);
    }

    if (document.getElementsByClassName("m-dropdown").length > 0) {
      unmountTooltip();
    } else if (element.id && document.getElementById(element.id)) {
      tooltip.classList.remove("m-tooltip--invisible");
      element.style.position = "";
    }
  };

  /**
   *
   * @param {HTMLElement} element Original element that originated this tooltip
   * @param {HTMLElement} draftTooltip
   */
  const createDynamicTooltip = (element, draftTooltip) => {
    const tooltip = draftTooltip;
    tooltip.style.top = "0px";
    tooltip.style.left = "0px";
    tooltip.classList.add("m-tooltip--dynamic");

    tooltipTimeout = null;

    document.body.appendChild(tooltip);

    const tooltipWidth = tooltip.offsetWidth;
    const tooltipHeight = tooltip.offsetHeight;

    document.body.removeChild(tooltip);

    currentTooltip.element = tooltip;
    currentTooltip.isActive = true;
    currentTooltip.type = "dynamic";
    currentTooltip.parent = element;
    currentTooltip.clickable =
      element?.attributes["data-tooltip-clickable"]?.value;

    tooltipsInMemory.set(element, tooltip);
    document.body.appendChild(tooltip);

    updateTooltipPosition(element, tooltipWidth, tooltipHeight, tooltip);

    element.addEventListener("mousemove", (evt) => {
      if (currentTooltip.clickable != "true") {
        updateTooltipPosition(element, tooltipWidth, tooltipHeight, tooltip);
      }
    });

    //  console.log("created dynamic tooltip", element);
  };

  /**
   *
   * @param {HTMLElement} element
   */
  const mountTooltip = (element) => {
    clearTimeout(tooltipTimeout);

    const content = element.getAttribute("data-tooltip-content");
    if (!content) return;
    const template = element.getAttribute("data-tooltip-template");
    const draftTooltip = document.createElement("tooltip");
    draftTooltip.id = Guid.NewGuid();

    if (template) {
      draftTooltip.innerHTML = computeTemplate(template, content);
    } else {
      draftTooltip.innerText = content;
    }

    const type = element.getAttribute("data-tooltip-type");
    draftTooltip.setAttribute("data-is-tooltip", "true");
    draftTooltip.classList.add("m-tooltip", "type--xsmall", "h6");
    draftTooltip.classList.add(`m-tooltip--${type}`);
    draftTooltip.classList.add("m-tooltip--invisible");

    const position =
      element.getAttribute("data-tooltip-position") ?? "bottom-right";

    if (position == "dynamic") {
      tooltipTimeout = setTimeout(() => {
        createDynamicTooltip(element, draftTooltip);
      }, 500);
    } else if (validPositions.includes(position)) {
      tooltipTimeout = setTimeout(() => {
        createStaticTooltip(element, draftTooltip, position);
      }, 500);
    } else {
      console.error(
        `Invalid attribute "data-tooltip-position": expected string with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center", or "bottom-right" and got "${position}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/917831805/Tooltip for instructions on how to use tooltips.`
      );
    }
  };

  /**
   * @param {string} templateName The template name
   * @param {string} d A json string with the variables that are in the template
   * @returns {string} The inner html of an HTMLElement
   */
  const computeTemplate = (templateName, d) => {
    /** @type {string} */
    let template = templates[templateName];
    if (!template) {
      throw new Error(
        `Template [${templateName}] not found in tooltips folder`
      );
    }

    const data = JSON.parse(d);
    const matches = template.match(new RegExp(/{{.*?}}/gm));

    for (const key in data) {
      const variable = data[key];
      const match = matches?.find((x) => x.includes(key));
      if (match) template = template.replaceAll(match, variable);
    }

    return template;
  };

  const unmountTooltip = () => {
    if (currentTooltip?.type == "dynamic") {
      currentTooltip.parent?.removeEventListener(
        "mousemove",
        // @ts-ignore
        updateTooltipPosition
      );
    }
    clearTimeout(tooltipTimeout);
    tooltipTimeout = null;
    cleanTooltips();
  };

  /**
   * @description Recursive search for a parent element with data-tooltip
   * @param {HTMLElement|null} element
   * @param {number} count
   * @returns {HTMLElement|null}
   */
  const findElementWithDataTooltip = (element, count) => {
    if (element?.hasAttribute?.("data-tooltip-content")) {
      if (element.getAttribute("data-tooltip-content") === "") {
        return null;
      } else {
        return element;
      }
    }
    //limit recursive search up to 5 levels
    if (element?.parentElement && count < 5) {
      return findElementWithDataTooltip(element.parentElement, count + 1);
    }

    return null;
  };

  /**
   * @description Function that initializes the tooltip functionality. ⚠⚠ Use only ONCE in the application
   */
  const initializeTooltipService = () => {
    document.addEventListener(
      "click",
      () => {
        if (currentTooltip?.isActive) unmountTooltip();
      },
      true
    );

    document.addEventListener("mousemove", (event) => {
      mouseCoordinates.x = event.clientX;
      mouseCoordinates.y = event.clientY;
      if (!(event.target instanceof Element)) return;
      const element = event.target;
      //avoid repeating verifications if the element still the same
      if (lastElement !== element) {
        const elementWithDataTooltip = findElementWithDataTooltip(element, 0);

        if (elementWithDataTooltip !== null) {
          clearTimeout(timeout);
          if (currentTooltip?.isActive) {
            //transition between two elements with different data-tooltip
            if (
              elementWithDataTooltip?.getAttribute("data-tooltip-content") !==
              currentTooltip.element?.innerText
            ) {
              unmountTooltip();
              mountTooltip(elementWithDataTooltip);
            }
          } else mountTooltip(elementWithDataTooltip);

          // means we have an active tooltip but mouse cursor is out of any element with tooltip
        } else if (currentTooltip?.isActive) {
          if (currentTooltip.clickable == "true") {
            if (event.target.attributes["data-is-tooltip"]?.value == "true") {
              clearTimeout(timeout);
            } else {
              timeout = setTimeout(() => {
                unmountTooltip();
              }, 300);
            }
          } else unmountTooltip();
        } else unmountTooltip();
      }
      lastElement = element;
    });
  };

  return {
    initializeTooltipService,
    updateTooltipContent,
  };
};
