<template>
  <div
    class="m-tiles m-wrapper"
    :class="
      appStore.fullscreen
        ? 'm-tiles--fullscreen'
        : `m-wrapper--${viewsStore.nav}`
    "
  >
    <div v-if="!appStore.fullscreen" class="m-subheader">
      <m-filter
        id="tiles_page_filter"
        :scoped="false"
        :modelValue="viewFilters"
        :placeholder="$t('components.search.placeholder_searchForAnything')"
      />
    </div>

    <!-- is loading -->
    <div
      v-if="!requestCompleted"
      id="scrollable"
      class="m-container"
      :class="{ 'mt-0': !appStore.fullscreen }"
    >
      <div class="m-container__wrapper">
        <m-tiles-skeleton />
      </div>
    </div>
    <div v-else id="scrollable" class="m-container">
      <div v-if="responseHasData" class="m-container__wrapper">
        <m-clustering :clusters="clusters" :initialDate="initialDate" />
      </div>
      <div v-else class="m-container__empty">
        <h3 class="type--small type--nowrap type--empty">
          {{ t("views.tiles.noSearchResults") }}
        </h3>
        <h6 class="type--small type--nowrap type--empty">
          {{ t("views.tiles.tryChanging") }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";

import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { useApi } from "@api/api";
import TimeFrames from "@utils/enums/timeFrames";

import { useViewFilters } from "@hooks/useViewFilters";

import MFilter from "@components/filter/MFilter.vue";
import MClustering from "@components/MClustering.vue";
import MTilesSkeleton from "@components/skeletons/MTilesSkeleton.vue";
import { useViewsStore } from "@root/store/modules/views";
import { useAppStore } from "@root/store/app";
import { useClustersStore } from "@root/store/modules/clusters";
import { isEmpty } from "lodash-es";

const { t } = useI18n();
const appStore = useAppStore();
const clustersStore = useClustersStore();
const viewsStore = useViewsStore();
const { api } = useApi();
const route = useRoute();
const router = useRouter();
const viewId = computed(() => route.params.viewId);

const { aggregator, queryObject, dateRestriction, decodeQueryObject } =
  useViewFilters(router, route, viewId.value);

const view = computed(() => viewsStore.getViewById(viewId.value));

const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },
  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
  },
});

const timeout = ref(null);
const inactivityThresholdMinutes = ref(2);
const events = ref([
  "onload",
  "mousedown",
  "mousemove",
  "keypress",
  "scroll",
  "touchstart",
]);
const requestCompleted = ref(false);
const responseHasData = ref(true);

const apiTopClustersData = ref([]); //Populated in getTopClusters()
/** Use this computed variable to pre process the clusters as needed for the UI */
const clusters = computed(() => {
  /**
   * Need to implement the translation logic here
   * Bellow is the old code that used use with:
   * return translateClusters(apiTopClustersData.value);
   */

  if (!apiTopClustersData.value?.clusters) return null;

  return handleClusters(apiTopClustersData.value.clusters);
});

/**
 * @param {import("@root/types").Monitio.URLQueryObject} query
 */
const getTopClusters = async (id, query) => {
  console.trace();
  const filters = query?.filters ?? [];
  if (!isEmpty(query.contextualFilters))
    filters.push({ facets: query.contextualFilters });

  const response = await api.search.topClusters(
    id,
    query.dateRestriction || dateRestriction.value,
    query.aggregator || aggregator.value,
    filters
  ); //start by seding view id to the backend today
  if (response.status != 200) return;

  // validation for when api respone come without data
  requestCompleted.value = true;
  if (!response.data?.clusters?.length) {
    responseHasData.value = false;
    return {
      clusters: [],
      internal_info: null,
      viewRestrictions: null,
    };
  } else {
    responseHasData.value = true;

    const result = {
      clusters: response.data.clusters,
      internal_info: response.data.internal_info,
      viewRestrictions: response.data.viewRestrictions,
    };
    clustersStore.updateCurrentClusters(result.clusters, view.value);
    for (const cluster of result.clusters) {
      if (cluster.id && cluster.title)
        clustersStore.addClusterName(cluster.id, cluster.title);
    }
    return result;
  }
};

/**
 * Function that handles the formatiing of the data, so that it can be correctly rendered
 * by d3.js inside the MClustering component
 */
const handleClusters = (clusters) => {
  const cluster = { name: "root", children: [], parent: null };
  const root = cluster.children;

  //var all_info_requests = new Array<Services.IPostedRequest<Services.IClusterInfoRequest>>();
  //var index_to_request_context = new Array<any>();

  clusters.forEach((cluster_response) => {
    if (cluster_response == undefined) return;

    let parent = root.find(function (g) {
      return g?.name == cluster_response.topic;
    });

    if (parent == null) {
      parent = {
        name: cluster_response.topic,
        color: "color_" + (root.length % 10),
        type: "cluster",
        children: [],
        parent: cluster,
      };
      root.push(parent);
    }

    const node = {
      name: cluster_response.id,
      value: cluster_response.selection_frequency,
      title: cluster_response.title,
      image: cluster_response.image_url,
      cluster_id: ["0", "1"],
      clusters_id: null,
      parent: parent,
    };
    parent.children.push(node);
  });

  return cluster;
};

let refreshInterval;
onMounted(async () => {
  const { clusters, viewRestrictions } = await getTopClusters(
    viewId.value,
    queryObject.value
  );
  apiTopClustersData.value = {
    clusters: clusters,
    viewRestrictions: viewRestrictions,
  };
  detectInactivity();

  //Setup pooling to automaticly update new articles
  refreshInterval = setInterval(async () => {
    const { clusters, viewRestrictions } = await getTopClusters(
      viewId.value,
      queryObject.value
    );
    if (!clusters) return;
    apiTopClustersData.value = {
      clusters: clusters,
      viewRestrictions: viewRestrictions,
    };
  }, 60000);
});

onBeforeUnmount(() => {
  appStore.setFullscreen(false);
});

onBeforeRouteUpdate((to, from) => {
  if (from.name != to.name) return true;
  /**
   * Handle the update of the props change
   * Ideally we wouls react to changes to the computed vars (viewId, queryObject) but that causes problems
   * betweens routes. So we need to use this hook, but can't use the computed vars because they only change AFTER
   * route change, and this hook runs BEFORE route change.
   **/
  const toQueryObject = decodeQueryObject(to.query.q);
  apiTopClustersData.value = []; // This is done so maybe a loading can take place? Else the user will view articles from another view for some milliseconds
  getTopClusters(to.params.viewId, toQueryObject).then(
    ({ clusters, viewRestrictions }) => {
      apiTopClustersData.value = {
        clusters: clusters,
        viewRestrictions: viewRestrictions,
      };
    }
  );
});

onUnmounted(() => {
  console.debug("Removing events listeners");
  events.value.forEach((name) => {
    document.removeEventListener(name, resetTimer.value, true);
  });
  console.debug("Clearing inactivity watcher timeout");
  clearTimeout(timeout.value);
  console.debug("Clearing auto refresh interval");
  clearInterval(refreshInterval);
});

const initialDate = computed(() => {
  return { crg: TimeFrames.Today };
});

const detectInactivity = () => {
  events.value.forEach((name) => {
    document.addEventListener(name, resetTimer, true);
  });
  resetTimer();
};

const resetTimer = () => {
  clearTimeout(timeout.value);
  timeout.value = setTimeout(
    refresh.value,
    inactivityThresholdMinutes.value * 60000
  );
};

const refresh = async () => {
  // await store.dispatch("changeDT0", currentRoute.value?.query?.dt0 || "");
  // await store.dispatch("changeDT1", currentRoute.value?.query?.dt1 || "");
  resetTimer();
  //location.href = 'logout.html'
};
</script>

<style scoped lang="scss">
.m-tiles {
  display: flex;
  flex-direction: column;

  .m-container {
    height: 100%;
    overflow: hidden;

    &__header {
      @include flex(flex-end, center, row);
    }

    &__wrapper {
      width: 100%;
      max-width: calc(100% - $spacing-4);
      margin: 0 auto;
      padding: $spacing-2 $spacing-3 $spacing-0;
    }

    &__empty {
      height: 100%;
      @include flex(center, center, column);
    }
  }

  &--fullscreen {
    width: 100%;
    padding-top: $spacing-0;

    .m-container {
      max-height: 100vh;
      padding: $spacing-0 $spacing-1;
    }

    .m-container__header {
      padding: $spacing-0;
      margin: $spacing-4 $spacing-0 $spacing-0;
      position: absolute;
      top: 0;
      z-index: $z-modal;

      > *:last-child {
        margin-right: $spacing-4;
      }
    }
  }
}
</style>
