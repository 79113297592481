<template>
  <div
    v-if="article"
    :ref="rootElement"
    class="m-article-page m-wrapper"
    :class="
      appStore.fullscreen
        ? 'm-article-page--fullscreen'
        : `m-wrapper--${viewsStore.nav}`
    "
  >
    <div id="scrollable" class="m-container">
      <div class="m-article-page__main">
        <div v-if="!appStore.fullscreen" class="mb-7 m-article-page__heading">
          <div
            v-if="selectedLang.value != articleLang.value"
            class="mt-2 mb-5 m-tooltip m-tooltip--grey p-relative h6 type--xsmall"
          >
            {{ translationDisclaimer }}
          </div>
          <div v-if="visibilityLabel" class="m-article-page__tags">
            <m-icon
              :icon="visibilityIcon"
              variant="white"
              status="active"
              class="m-article-page__tag"
            />
            <p class="h5 type--small">
              {{ visibilityLabel }}
            </p>
          </div>
          <h1
            class="mb-2 type--small m-article-page__title"
            :dir="langDirection(selectedLang)"
          >
            {{ articleTitle ?? t("article.main_title_untitled") }}
          </h1>
          <div
            class="m-article-page__details"
            :class="`m-article-page__details--${langDirection(selectedLang)}`"
          >
            <m-feed-link :feed="feed" :dir="langDirection(selectedLang)" />
            <div class="m-article-page__feed-info">
              <div v-for="(info, i) in feedInfo" :key="i">
                <h6 class="type--small">{{ info }}</h6>
                <div
                  v-if="feedInfo.length > 1 && i != feedInfo.length - 1"
                  class="mx-2 m-divider"
                ></div>
              </div>
            </div>
          </div>

          <h6 class="type--xsmall">
            <!--             {{ feed.handle }}
            <span v-if="tweet" class="mx-3">|</span>
            {{ country }}
            <span v-if="country" class="mx-3">|</span>
            {{ type }}
            <span v-if="type" class="mx-3">|</span> -->
            <span v-if="articleAuthor" class="type--500">
              {{ articleAuthor }} <span class="mx-1">·</span>
            </span>
            {{ date }}
          </h6>
        </div>
        <!-- <m-media-player v-if="true" :src="video" class="my-7" /> -->
        <iframe
          v-if="youtube"
          width="560"
          height="315"
          :src="embed"
          class="mb-7"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div v-else-if="tweet" class="mb-7 tweet">
          <blockquote v-if="embed" class="twitter-tweet">
            <p v-html="embed.content" lang="en" dir="ltr"></p>
            {{ `&mdash; ${embed.name} (${embed.handle})` }}
            <a :href="embed.link">{{ embed.date }}</a>
          </blockquote>
          <div v-else>No tweet content detected.</div>
        </div>
        <div v-else-if="facebook && facebookURL" class="mb-7 tweet">
          <div
            id="fb-post"
            class="fb-post"
            :data-href="facebookURL"
            data-width="500"
            data-show-text="true"
          ></div>
        </div>
        <!-- <video
          v-else-if="videoUrl"
          width="560"
          height="315"
          controls
          class="my-7"
        >
          <source :src="videoUrl" type="video/mp4" />
          {{ t("article.main_videoNotSupported") }}
        </video> -->
        <m-image
          v-else-if="image"
          class="mb-7 m-article-page__image"
          :src="image"
        />

        <div
          v-if="videoTranscription?.value && !appStore.fullscreen"
          :dir="langDirection(videoTranscription.lang)"
          class="mb-7 m-article-page__transcription"
          :class="`m-article-page__transcription--${videoTranscriptionExpanded}`"
        >
          <div
            id="m_article_video_transcription_heading"
            tabindex="0"
            role="button"
            :aria-expanded="videoTranscriptionOpen"
            aria-controls="m_article_video_transcription"
            class="m-transcription__heading"
            @click="toggleVideoTranscription"
            @keyup.enter="toggleVideoTranscription"
          >
            <span class="h4">
              {{ t("article.main_videoTranscription") }}
            </span>
            <m-icon
              :icon="videoTranscriptionExpanded"
              variant="secondary"
              size="small"
              @click="toggleVideoTranscription"
              @keyup.enter="toggleVideoTranscription"
            />
          </div>
          <p
            id="m_article_video_transcription"
            role="region"
            labelledby="m_article_video_transcription_heading"
            class="m-transcription__content"
          >
            <m-text-formatter
              :text="videoTranscription.value"
              :format="transcriptionTextFormat"
              :langDirection="langDirection(videoTranscription.lang)"
              :canEditEntities="canEditEntities"
              :showTranslation="showTranslation"
              :article="article"
              :selectedLang="selectedLang"
              :entityWikidata="entityWikidata"
              @entityUpdated="entityUpdated"
            />
          </p>
        </div>
        <div
          v-if="
            !tweet &&
            !youtube &&
            article.metadata?.compliance?.visibility != 'restricted' &&
            !appStore.fullscreen
          "
          class="mb-7 m-article-page__summary"
        >
          <template v-if="requestedSummary && summary?.value">
            <h4 class="mb-2" :dir="langDirection(summary.lang)">
              {{ t("article.main_summary") }}
              <m-icon
                id="m_filter_ai_indo"
                icon="info"
                size="xsmall"
                :tooltip="summaryDisclaimer"
              />
            </h4>
            <ul :dir="langDirection(summary.lang)">
              <li v-for="(bullet, i) in summary?.value" :key="i" class="mb-2">
                <p class="type--reading">{{ bullet?.text }}</p>
              </li>
            </ul>
          </template>
        </div>

        <div
          v-if="!tweet && !appStore.fullscreen"
          class="mt-5 m-article-page__body"
        >
          <h4
            v-if="article.metadata?.compliance?.visibility != 'restricted'"
            class="mb-2"
          >
            {{
              youtube
                ? t("article.main_videoDescrition")
                : t("article.main_article")
            }}
          </h4>
          <!--        <m-text-formatter
            v-if="article.header?.sourceItemTeaser"
            :text="article.header?.sourceItemTeaser"
            :format="textFormat"
            :canEditEntities="true"
            :showTranslation="showTranslation"
          /> -->
          <p
            v-if="
              ['open', 'first256'].includes(
                article.metadata?.compliance?.visibility
              )
            "
            class="type--reading"
          >
            <m-text-formatter
              v-if="text"
              :text="text"
              :format="textFormat"
              :langDirection="langDirection(selectedLang)"
              :canEditEntities="canEditEntities"
              :showTranslation="showTranslation"
              :article="article"
              :selectedLang="selectedLang"
              :entityWikidata="entityWikidata"
              @entityUpdated="entityUpdated"
            />
          </p>
          <h6
            v-else-if="article.metadata?.compliance?.visibility == 'embargoed'"
            class="mt-4 type--empty"
            v-html="
              t('article.embargoed_message', {
                link: articleURL,
                date: DateTime.fromISO(
                  article.metadata?.compliance?.embargoedUntil,
                  { zone: 'utc' }
                )
                  .toJSDate()
                  .toLocaleString(userLocale, {
                    ...DateTime.DATETIME_SHORT,
                    timeZone: userTimezone,
                  }),
              })
            "
          ></h6>
          <h6
            v-else-if="article.metadata?.compliance?.visibility == 'expired'"
            class="mt-4 type--empty"
            v-html="
              t('article.expired_message', {
                link: articleURL,
                days: article.metadata?.compliance?.maxAge,
              })
            "
          ></h6>
          <h6
            v-else
            v-html="
              t('article.main_fullTextUnavailable', {
                link: articleURL,
              })
            "
            class="mt-4"
          ></h6>
          <h6 v-if="copyrightMessage" class="py-7">
            {{ copyrightMessage }}
          </h6>
        </div>
      </div>
      <m-article-side
        v-if="!appStore.fullscreen"
        :article="article"
        :entityWikidata="entityWikidata"
        :selectedLang="selectedLang"
        :availableLangs="availableLangs"
        class="mt-7 pt-7"
        :mainTextDetectedTypedScopes="mainTextDetectedTypedScopes"
        @update:article="
          (key, val) => {
            article[key] = val;
          }
        "
        @read-article="readArticle(article)"
        @unread-article="unreadArticle(article)"
        @toggle-selected-lang="(lang) => (selectedLang = lang)"
        @open-article="openVideoTranscription"
      />
    </div>
  </div>
  <div v-else-if="!canAccessArticle" class="m-article-page__access-denied">
    <m-image :src="restricted_illustration" class="m-access-denied__image" />
    <h2 class="type--small type--empty">
      {{ t("article.accessDenied_title") }}
    </h2>
    <h6 class="type--small type--empty type--center">
      {{ t("article.accessDenied_description") }}
    </h6>
  </div>
  <!-- is loading -->
  <div v-else class="m-article-page">
    <m-article-page-skeleton />
  </div>
</template>

<script setup>
/* eslint-disable no-unreachable */
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

import { useRouter, useRoute } from "vue-router";
import base_image from "@assets/images/monitio_empty.png";
import { useApi } from "@api/api";
import { checkForWikiImage } from "@utils/utils";
import { transformFormatting } from "@utils/textFormatterUtils";
import useDateFormat from "@hooks/useDateFormat";
import { useFacebookWidget, useTwitterWidget } from "@hooks/useExternalLibs";
import useTranslator from "@hooks/useTranslator";
import useArticle from "@hooks/useArticle";
import useEditMentionModal from "@components/modals/MEditMention/useEditMentionModal";

import MFeedLink from "@components/MFeedLink.vue";
import MArticleSide from "@components/MArticleSide.vue";
import MTextFormatter from "@components/MTextFormatter.vue";
import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";

import MArticlePageSkeleton from "@components/skeletons/MArticlePageSkeleton.vue";
import structuredClone from "@root/utils/structuredClone";
import { DateTime } from "luxon";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useNavigationStore } from "@root/store/modules/navigation";

import restricted_illustration from "@assets/illustrations/restricted.svg";

const { t } = useI18n();
const { api } = useApi();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const appStore = useAppStore();
const workspacesStore = useWorkspacesStore();
const navigationStore = useNavigationStore();
const router = useRouter();
const route = useRoute();
const { dateFormat } = useDateFormat();
const { localizeCountryCode, localizeLanguageCode } = useTranslator();
const { open, close, cancel } = useEditMentionModal();

const viewId = computed(() => {
  if (route.params.viewId == "" || !route.params.viewId)
    return workspacesStore.currentWorkspaceConfig?.baseViewId;
  return route.params.viewId;
});
const { readArticle, unreadArticle } = useArticle(viewId);

/** Need to code some error handling here, because need to warn developers if this fails */
const articleId = computed(() => route.params.articleId);

const rootElement = ref(null);

/** @type {import("vue").Ref<import("@root/types.api.local").MonitioAPI.SearchDocumentDTO | import("vue").Ref<null>}> */
const article = ref(null);
const canAccessArticle = ref(true);
const userLocale = computed(() => userStore.i18nLanguage);
const userLang = computed(() => userLocale.value.split("-")[0]);
const userTimezone = ref(userStore.timeZone);
const articleLang = computed(() => article.value?.documentLang);
const articleTranscriptionLang = computed(
  () => article.value?.transcriptionLang
);
const selectedLang = ref(
  userStore.account?.translateFrom?.find(
    (x) => x == articleLang.value?.split("-")[0]
  ) && availableLangs.value.includes(userLang.value)
    ? userLang.value
    : articleLang.value
);

const langDirection = (lang) => {
  return (
    workspacesStore.languages.find((x) => x.isoCode == lang.split("-")[0])
      ?.direction ?? "lrt"
  );
};

const translationDisclaimer = computed(() => {
  const language =
    localizeLanguageCode(articleLang.value).charAt(0).toUpperCase() +
    localizeLanguageCode(articleLang.value).slice(1);
  return t("article.main_translated", { language });
});

const articleTranslations = computed(() => article.value?.translations);

/** @description stores an array with all avaiable languages in wich there is main text */
const availableLangs = computed(() =>
  Object.keys(articleTranslations.value).filter(
    (key) => articleTranslations.value[key].title
  )
);

const articleURL = computed(
  () =>
    article.value?.metadata?.cannonicalUrl ??
    article.value?.metadata?.sourceItemUrlList?.[0]
);

watch(
  () => articleLang.value,
  (lang) => {
    if (
      userStore.account?.translateFrom?.find((x) => x == lang?.split("-")[0]) &&
      availableLangs.value.includes(userLang.value)
    ) {
      // The user by default wants to translate from this article's native language
      selectedLang.value = userLang.value;
    } else {
      selectedLang.value = lang;
    }
  }
);

watch(
  () => selectedLang.value,
  (lang) => {
    if (!lang) return;
    updateSummary(lang);
  }
);

const articleVisibility = computed(
  () => article.value?.metadata?.compliance?.visibility
);

const articleBilling = computed(() => {
  const billingType = article.value?.metadata?.compliance?.billingType;
  return billingType?.split("_")[0];
});

const visibilityIcon = computed(() => {
  if (articleVisibility.value) return articleVisibility.value;
  else if (articleBilling.value) return articleBilling.value;
  else return "";
});

const visibilityLabel = computed(() => {
  if (articleBilling.value == "premium") {
    return t(`article.main_${articleBilling.value}`);
  } else if (
    articleVisibility.value == "expired" ||
    articleVisibility.value == "restricted" ||
    articleVisibility.value == "embargoed"
  ) {
    return t(`article.main_${articleVisibility.value}`);
  } else return null;
});

const tweet = computed(() =>
  article.value?.metadata?.sourceItemUrlList?.some(
    (x) => x.includes("twitter.com") || x.includes("://x.com")
  )
);
const youtube = computed(() =>
  article.value?.metadata?.sourceItemUrlList?.some(
    (x) => x.includes("youtube.com") || x.includes("youtu.be")
  )
);

const facebookURL = ref(null);
const facebook = computed(() =>
  article.value?.metadata?.sourceItemUrlList?.some((x) =>
    x.includes("facebook.com")
  )
);

const videoTranscription = computed(() => {
  if (!articleTranscriptionLang.value) return null;
  let lang = selectedLang.value;
  let text = articleTranslations.value?.[selectedLang.value]?.transcription;
  if (!articleTranslations.value?.[selectedLang.value]?.transcription) {
    lang = articleTranscriptionLang.value;
    text =
      articleTranslations.value?.[articleTranscriptionLang.value]
        ?.transcription;
  }

  return {
    lang: lang,
    value: text,
  };
});

const videoTranscriptionExpanded = ref("expand-vertical");
const videoTranscriptionOpen = computed(() => {
  return videoTranscriptionExpanded.value == "collapse-vertical";
});
const openVideoTranscription = () => {
  videoTranscriptionExpanded.value = "collapse-vertical";
};
const closeVideoTranscription = () => {
  videoTranscriptionExpanded.value = "expand-vertical";
};
const toggleVideoTranscription = () => {
  if (videoTranscriptionOpen.value) {
    closeVideoTranscription();
  } else openVideoTranscription();
};

const embed = computed(() => {
  if (tweet.value) {
    const twitter = article.value?.metadata?.socialMediaInformation?.twitter;
    if (articleTranslations.value?.[selectedLang?.value]?.mainText) {
      const embed = {};
      embed.link = articleURL.value;
      embed.date = article.value?.date;

      embed.content = articleTranslations.value?.[selectedLang?.value]?.mainText
        .split(" ")
        .map((m) => {
          if (m.includes("http")) {
            return `<a href="${m}">${m}</a>`;
          } else return m;
        })
        .join(" ");

      embed.name = twitter?.Name ?? "";
      embed.handle = twitter?.Handle ?? "";
      return embed;
    } else return null;
  } else if (youtube.value) {
    const url = articleURL.value;
    const id = url.split("v=")[1];
    return `https://www.youtube.com/embed/${id}`;
  } else return null;
});

const articleTitle = computed(() => {
  let title = articleTranslations.value?.[selectedLang?.value]?.title;
  if (!title) {
    title = articleTranslations.value?.[articleLang?.value]?.title;
  }
  return title;
});

const articleAuthor = computed(() => article.value.articleAuthor);

const feed = computed(() => {
  const feed = {};
  if (tweet.value) {
    feed.handle =
      article.value?.metadata?.socialMediaInformation?.twitter?.Handle;
  }
  feed.name = article.value?.metadata?.sourceItemFeeds?.[0]?.name;
  feed.url = articleURL.value;
  if (article.value?.metadata?.sourceItemIdAtOrigin?.includes("PMC")) {
    feed.url = `https://pubmed.ncbi.nlm.nih.gov/${article.value.metadata.sourceItemIdAtOrigin}`;
  }

  return feed;
});

const feedInfo = computed(() => {
  const platform = article.value.metadata?.sourceItemTypedScopes?.platform?.[0];

  const feedCountry = localizeCountryCode(
    article.value.metadata?.sourceItemTypedScopes?.feedCountry?.[0] ?? ""
  );

  const district = localizeCountryCode(
    article.value.metadata?.sourceItemTypedScopes?.district?.[0] ?? ""
  );

  const info = [];

  if (feedCountry) info.push(feedCountry);
  if (district) info.push(district);
  if (platform) info.push(platform);

  return info;
});

const image = computed(() => {
  const img = article.value.thumbnail;
  if (img == textFormat.value?.find((f) => f.tag == "img")?.src) {
    return "";
  } else return img;
});

const date = computed(() => {
  return dateFormat(article.value?.date, "dynamic");
});

const getArticle = async (id) => {
  const vid = viewId.value;
  try {
    const response = await api.search.getDocument(vid, id); //start by seding view id to the backend today
    if (response.status == 200) return response.data;
    if (response.response.status == 404 && response.response.data) {
      //view was not found in the workspace, so check if the user has acess to the workspace of the view warn and switch
      const viewWId = response.response.data;
      const hasAccess = workspacesStore.user
        ?.filter((x) => x.active)
        .find((x) => x.id == viewWId);

      if (hasAccess) {
        router.push({
          name: "switch-workspace",
          params: {
            from: `${route.name}!`,
            viewId: vid,
            workspaceId: hasAccess.id,
            targetId: id,
          },
        });
      } else throw new Error("No access");
    }
    return;
  } catch (error) {
    canAccessArticle.value = false;
    return null;
  }
};

// SUMMARY
const requestedSummary = ref(false);
const summaries = ref([]);

const summaryDisclaimer = computed(() => {
  return { content: t("disclaimer.ai_summary_article"), position: "right" };
});

const summary = computed(() => {
  const articleCharCount =
    article.value.translations[articleLang.value].mainText.trim().length;
  const maxCharCountToSummary =
    window._env_.VUE_APP_DEFAULT_MAX_CHAR_NO_SUMMARY;
  if (articleCharCount > maxCharCountToSummary) {
    const selectedLangSummary = summaries.value.find(
      (x) => x.lang == selectedLang.value
    );
    if (selectedLangSummary?.value) return selectedLangSummary;
    const englishLangSummary = summaries.value.find((x) => x.lang == "en");
    if (englishLangSummary?.value) return englishLangSummary;

    return summaries.value.find((x) => x.value);
  } else return null;
});

const updateSummary = async (lang) => {
  console.log("udapteing sumary");
  const articleCharCount =
    article.value.translations[articleLang.value].mainText.trim().length;
  const maxCharCountToSummary =
    window._env_.VUE_APP_DEFAULT_MAX_CHAR_NO_SUMMARY;
  if (articleCharCount <= maxCharCountToSummary) return; //No need to summarize, the text is to short
  if (summaries.value.some((x) => x.lang == lang)) return; //Summary already there (do nothing)

  requestedSummary.value = false;
  /** @type {{data : import("@root/types.api.local").MonitioAPI.SummaryDTO}} */
  const { data } = await api.search.summarizeDocument(lang, article.value);

  if (data) {
    let items;
    if (data.highlights.filter((x) => x.translatedLanguage == lang).length) {
      items = data.highlights.filter((x) => x.translatedLanguage == lang);
    } else items = data.highlights.filter((x) => x.originalLanguage == lang);

    summaries.value.push({
      lang: lang,
      value: items.map((x) => ({
        text: x.translated ?? x.original,
      })),
    });
    requestedSummary.value = true;
  }
};
// END SUMMARY

// ARTICLE TEXT
const canEditEntities = computed(
  () => userStore.details.featureAccess?.["editEntities"] ?? false
);
const showTranslation = ref(false);
const mainTextDetectedTypedScopes = computed(() => {
  const lang = articleTranslations.value?.[selectedLang.value]
    ?.detectedTypedScopes?.mainText
    ? selectedLang.value
    : articleLang.value;

  if (!lang) return [];
  return articleTranslations.value?.[lang]?.detectedTypedScopes?.mainText;
});

const mainTextEntities = computed(() => {
  const scopes = Object.keys(mainTextDetectedTypedScopes.value ?? {}) ?? [];

  return scopes.reduce((prv, crr) => {
    const scope = mainTextDetectedTypedScopes.value[crr];
    // console.log(crr);
    return prv.concat(
      scope.map((x) => ({
        ...x,
        namespace: crr == "entities" ? "wikidata" : crr,
      }))
    );
  }, []);
});

const transcriptionDetectedTypedScopes = computed(() => {
  const lang = articleTranslations.value?.[selectedLang.value]
    ?.detectedTypedScopes?.transcription
    ? selectedLang.value
    : userLang.value;

  console.log("translang", lang);

  if (!lang) return [];
  return articleTranslations.value?.[lang]?.detectedTypedScopes?.transcription;
});

const transcriptionEntities = computed(() => {
  const scopes =
    Object.keys(transcriptionDetectedTypedScopes.value ?? {}) ?? [];

  return scopes.reduce((prv, crr) => {
    const scope = transcriptionDetectedTypedScopes.value[crr];
    return prv.concat(
      scope.map((x) => ({
        ...x,
        namespace: crr == "entities" ? "wikidata" : crr,
      }))
    );
  }, []);
});

/** @description storage of the extra inforamtion about the entities in the document. Bellow this is a watch that is repsonsible for data fetching */
const entityWikidata = ref([]);
watch(
  () => [mainTextEntities.value, transcriptionEntities.value],
  async (
    [mainTextEntVal, transcriptionEntVal],
    [mainTextOldEnt, transcriptionOldEnt]
  ) => {
    const value =
      [...mainTextEntVal, ...transcriptionEntVal]?.filter(
        (x) => x.namespace == "wikidata"
      ) ?? [];
    //If at some point the user changes language and there's no entities on that language don't change anyhting in the wikidata
    if (!value?.length) return;

    //if (oldValue?.length == value.length) return;
    if (entityWikidata.value.length == 0) {
      //This should only happen on document load
      entityWikidata.value = value.map((e) => {
        const isNIL = e.label?.includes("NIL");

        return {
          id: e.key ?? e.id ?? e.label,
          form: e.label ?? e.baseForm,
          label: (isNIL ? e.baseForm : e.label) ?? e.baseForm,
          type: isNIL ? "nil_" + e.type.replace("nil_", "") : e.type,
          uri: e.uri,
          isNIL: isNIL,
        };
      });
    }

    /** Remove the entities from entityWikidata if needed (usefull when user unlinks entities) */
    entityWikidata.value = entityWikidata.value.filter(
      (entry) =>
        value.map((x) => x.key).includes(entry.id) ||
        value.map((x) => x.label).includes(entry.id)
    );

    for (const entity of value) {
      const localEntry = entityWikidata.value.find(
        (x) => x.id == entity.key || x.id == entity.label
      );
      if (!localEntry) {
        const isNIL = entity.label?.includes("NIL");
        entityWikidata.value.push({
          id: entity.key ?? entity.label,
          form: entity.label ?? entity.baseForm,
          label: isNIL ? entity.baseForm : entity.label ?? entity.baseForm,
          type: isNIL ? "nil_" + entity.type.replace("nil_", "") : entity.type,
          uri: entity.uri,
        });
      }
    }

    for (const entity of value) {
      const localEntry = entityWikidata.value.find(
        (x) => x.id == entity.key || x.id == entity.label
      );
      setTimeout(() => {
        if (localEntry?.image !== undefined) return; //Don't waste API calls, image is already here
        checkForWikiImage(
          userLang.value,
          localEntry.baseForm,
          localEntry.label,
          articleLang.value
        ).then((val) => {
          const idx = entityWikidata.value.findIndex(
            (x) => x.id == entity.key || x.id == entity.label
          );
          if (idx < 0) return;
          entityWikidata.value[idx].page = val?.page;
          entityWikidata.value[idx].image = val?.image ?? base_image;
        });
      }, 800);
    }
  }
);

const format = computed(() => {
  if (!articleTranslations.value?.[selectedLang.value]?.formatting?.mainText)
    return [];
  return transformFormatting(
    articleTranslations.value[selectedLang.value].formatting.mainText,
    article.value
  );
});

const mentions = computed(() => {
  if (!mainTextEntities.value?.length) return [];

  return mainTextEntities.value
    .reduce((p, c) => {
      return p.concat(
        c.mentions
          .map((m) => m.mention || m)
          .map((m) => ({
            ...m,
            start: m.startPosition.offset,
            end: m.endPosition.offset,
            ord: Number.MAX_VALUE,
            tag: "entity-link",
            entity: c,
          }))
      );
    }, [])
    .filter((f) => {
      return !format.value
        .find((x) => x.lang == articleLang.value)
        ?.value?.find((s) => {
          if (s)
            return (
              s.end > f.start &&
              f.end > s.start &&
              (f.start < s.start || f.end > s.end)
            );
        });
    });
});

/** @description Has the text corresponsing to the selected language, or english, or if not avaiable the native article language*/
const text = computed(() => {
  const selectedLangText = articleTranslations.value[selectedLang.value];
  if (selectedLangText?.mainText) return selectedLangText.mainText;
  return "";
});

const textFormat = computed(() => {
  // Find the text format corresponding to the text language. If there's none return empty
  return [...(format.value ?? []), ...(mentions.value ?? [])];
});

const transcriptionTextFormat = computed(() => {
  const videoTransSelectedLang = videoTranscription.value.lang;
  if (!videoTransSelectedLang) return [];

  //#region Handle entities mentions here
  const selectedLangEntites = transcriptionEntities.value;
  const orderedMentions = selectedLangEntites
    .reduce(
      (p, c) =>
        p.concat(
          c.mentions.map((m) => {
            if (!m.mention) return;
            const clone = structuredClone(m.mention); // Avoid cyclic dependency later
            clone.entity = { ...c };
            return clone;
          })
        ),
      []
    )
    .filter((x) => x)
    .sort((a, b) => a.startPosition.offset - b.startPosition.offset);

  const processedEntities = orderedMentions.map((m, idx) => ({
    ...m,
    start: m.startPosition.offset,
    end: m.endPosition.offset,
    ord: 100 + idx,
    tag: "entity-link",
  }));
  //#endregion

  const transcriptionSegments = (
    article.value.translations?.[videoTransSelectedLang]?.formatting
      ?.transcription ?? []
  ).map((x) => {
    x.text = x.text.replaceAll("\n", " ");
    return x;
  });

  let count = 0;

  /** Gets the oder the segment should be relative to other formattings that might exist, mainly entitiy mentions */
  const getOrder = (start, end, idx) => {
    const previousMention = processedEntities
      .filter((x) => x.start < start)
      .sort((a, b) => b.start - a.start)
      .at(0);

    if (!previousMention) return idx;

    return (
      previousMention.ord + (1 - previousMention.start / (start + idx * 10))
    );
  };

  const segmentsByBlockId = transcriptionSegments.reduce((p, c, idx) => {
    const existingSegment = p.find((x) => x.id == c.id);
    if (existingSegment) {
      existingSegment.text += " " + c.text;
      existingSegment.end = existingSegment.start + existingSegment.text.length;
      existingSegment.endTime += " " + c.end;
    } else {
      const start = videoTranscription.value.value.indexOf(c.text);
      const end = start + c.text.length;
      if (end - start != c.text.length) {
        console.log("start", start);
        console.log("end", end);
        console.log("lenght", c.text);
        console.log(videoTranscription.value.value, c.text);
      }
      p.push({
        id: c.id,
        text: c.text,
        start: start,
        startTime: c.start,
        endTime: c.end,
        end: end,
        ord: getOrder(start, end, idx),
        tag: "transcription-segment",
      });
    }
    count += c.text.length;
    return p;
  }, []);
  return processedEntities.concat(segmentsByBlockId);
});

// END SIDE

const copyrightMessage = computed(() => {
  return article.value?.metadata?.compliance?.copyrightMessage;
});

const setSelection = (e) => {
  const className = e.target?.className;
  if (["m-text-formatter", "type--reading"].includes(className)) {
    // open("create", newMention, article.value);
  }
};

watch(
  () => articleTitle.value,
  (val) => {
    if (val) navigationStore.setArticleTitle(val);
  },
  { immediate: true }
);

watch(
  () => articleId.value,
  async (val) => {
    if (val == undefined) return;

    // Reeset some stuff
    article.value = null;
    summaries.value = [];
    selectedLang.value = null;

    article.value = await getArticle(val);
    if (article.value) readArticle(article.value);
  }
);

onMounted(async () => {
  article.value = await getArticle(articleId.value);
  if (article.value) readArticle(article.value);
  /** On article loading mark it as read right away */
  //appStore.setPinnedPane(false);
  document.addEventListener("mouseup", setSelection);
  document.addEventListener("keyup", setSelection);

  if (facebook.value) {
    useFacebookWidget();
    const { data } = await api.documents.resolveFBUrl(articleURL.value);
    facebookURL.value = data;
  }

  if (tweet.value) useTwitterWidget(articleURL.value);
});

const updateFormatter = ref(0);
const entityUpdated = (newDoc) => {
  if (!newDoc) return;
  // After any entity is update we need to rerender the entire text
  const clone = structuredClone(article.value);
  clone.translations = newDoc.translations;
  article.value = clone;

  //  article.value.header = document;
  updateFormatter.value++;
};

onUnmounted(() => {
  document.removeEventListener("mouseup", setSelection);
  document.removeEventListener("keyup", setSelection);
});
</script>

<style lang="scss">
@import "@stylesheets/scss/components/articlepage";

.m-article-page__title {
  width: 100%;
}
</style>
